.showcase {
  &__section {
    display: flex;
    justify-content: center;
    padding: 120px 0;
  }

  &__item {
    max-width: 300px;
    margin-right: 120px;

    &:last-child {
      margin-right: 0;
    }

    img {
      height: 190px;
      width: auto;
      margin-bottom: 48px;
    }
  }

  &__text {
    position: relative;
    flex: 1;
  }

  &__label {
    font-weight: _font(weight-bold);
    font-size: 16px;
    line-height: 24px;
    color: _palette(purple-vibrant);
    margin-bottom: 12px;

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }

  &__title {
    margin-bottom: 16px;

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }

  &__paragraph {
    font-weight: _font(weight-regular);
    letter-spacing: -0.02em;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
    margin-bottom: 12px;

    [data-theme='dark'] & {
      color: _dark-mode(gray);
    }
  }

  &__coming-soon {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: _palette(purple-vibrant);
    // margin-bottom: 12px;
    text-transform: uppercase;

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }
}

.device-showcase {
  height: 80vh;
  width: 100%;
  max-width: 1500px;
  box-sizing: border-box;
  padding: 0 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1400px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 640px) {
    height: 100vh;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: center;
  }

  .preview-ctn {
    margin-left: 100px;
    flex: 3;
    height: 100%;
    position: relative;

    .dsktp-preview {
      position: absolute;
      margin: auto;
      top: -60px;
      bottom: 0;
      left: 180px;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }

    .mbl-preview {
      position: absolute;
      margin: auto;
      top: 80px;
      bottom: 0;
      box-shadow: 0px 32px 64px -20px rgba(50, 50, 93, 0.1),
        0px 20px 40px -10px rgba(0, 0, 0, 0.1);
      border-radius: 40px;
    }
  }
}

.interface-showcase {
  height: calc(100vh - 100px);
  width: 100%;
  max-width: 1500px;
  box-sizing: border-box;
  padding: 0 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1400px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 640px) {
    height: 100vh;
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: center;
  }

  .preview-ctn {
    margin-left: 100px;
    flex: 3;
    max-height: 625px;
    position: relative;

    .dsktp-preview {
      position: absolute;
      margin: auto;
      height: 625px;
      top: -60px;
      bottom: 0;
      left: 0;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
  }
}
