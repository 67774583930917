.video {
  &__container {
    // border-radius: 10px 10px 0 0;
    // overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: -1px -1px 0; // Ensure no gaps between video and card edge

    @media screen and (max-width: 640px) {
      border-radius: 0;
    }

    &:before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: 56.25%;
    }

    &:after {
      /* to clear float */
      content: '';
      display: table;
      clear: both;
    }

    video {
      width: 180px;
      height: 180px;
      object-fit: cover;
      border-radius: 50%;
      display: block;

      @media screen and (min-width: 641px) {
        max-height: 60vh;
      }
    }
  }
}

.VideoPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 1px solid #242525;

  [data-theme='dark'] & {
    background: _dark-mode(dark-1);
  }

  &.mbl.local {
    border: none;
    display: flex;
    justify-content: flex-end;
    background: #000000;
  }

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 56.25%;
  }

  &:after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }

  &__avatar {
    // background: _palette(black);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    border-radius: 50%;
    margin: 0 auto;
    width: 90px;
    height: 90px;

    svg,
    img {
      width: 100%;
      height: 100%;
    }

    img {
      background: rgba(255, 255, 255, 0.15);
      border-radius: 50%;
    }

    &.mbl {
      border: 1px solid #555656;
    }

    &.mbl.local {
      width: 40px;
      height: 40px;
      // margin: 10px 0;
    }
  }
}
