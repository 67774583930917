@charset 'UTF-8';

@import '~bootstrap/scss/bootstrap';

@import 'vendors/rem';

// Library files
@import 'libs/functions', 'libs/mixins', 'libs/skel', 'libs/vars';

// Vendors
@import 'vendors/normalize';

@import 'css-grid';

// Base stuff
@import 'base/base', 'base/layout', 'base/text', 'base/fonts', 'base/typography',
  'base/whitespace';

@import 'vendors/animate';

// Components
@import 'components/avatar', 'components/participant', 'components/controls',
  'components/footer', 'components/card', 'components/topnav',
  'components/video', 'components/toggle', 'components/form',
  'components/loading', 'components/tile', 'components/showcase',
  'components/notice', 'components/toast', 'components/header',
  'components/deviceselector', 'components/modal', 'components/minimap',
  'components/chat';

// Pages
@import 'pages/landing', 'pages/room', 'pages/meet', 'pages/create-account',
  'pages/sign-in', 'pages/onboarding', 'pages/home', 'pages/settings',
  'pages/admin', 'pages/metaspace', 'pages/events', 'pages/universe-settings',
  'pages/create-universe', 'pages/event-settings';
