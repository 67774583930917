.Toggle {
  input[type='checkbox'] {
    -webkit-appearance: none;
    visibility: hidden;
    display: none;
  }
  .check {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    background: #092c3e;
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    transition: ease-in 0.5s;
  }
  input[type='checkbox']:checked ~ .check {
    background: #fff;
    box-shadow: 0 0 0 2000px #202020;
  }
  .check:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    background: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.5s;
  }
  input[type='checkbox']:checked ~ .check:before {
    transform: translateX(-50px);
  }
  .check:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    background: #092c3e;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.5s;
    transform: translateX(50px);
  }
  input[type='checkbox']:checked ~ .check:after {
    transform: translateX(0px);
  }
}

.DarkModeToggle {
  --scale: 26px;
  opacity: 0.9;
  transition: opacity 0.15s ease-out;

  &:hover {
    opacity: 1;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    visibility: hidden;
    display: none;
  }

  .check {
    position: relative;
    display: block;
    width: var(--scale);
    height: var(--scale);
    cursor: pointer;
    transition: ease-out 0.15s;
  }

  .sun {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--scale);
    height: var(--scale);
    transition: all 0.5s ease-out;
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }

  input[type='checkbox']:checked ~ .check .sun {
    transform: translateX(-40px);
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }

  .moon {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--scale);
    height: var(--scale);
    transition: all 0.5s ease-out;
    transform: translateX(40px);
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }

  input[type='checkbox']:checked ~ .check .moon {
    transform: translateX(0px);
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }

  /**
   * The general sibling combinator is made of the
   * "tilde" (U+007E, ~) character that separates
   * two sequences of simple selectors. The elements
   * represented by the two sequences share the same
   * parent in the document tree and the element
   * represented by the first sequence precedes (not
   * necessarily immediately) the element represented
   * by the second one.
   */
}

.multi-state-toggle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  background: #e9eaff;
  border-radius: 16px;
  width: 300px;
  margin: 0 auto;
  color: #3239f1;

  [data-theme='dark'] & {
    background: _dark-mode(dark-3);
    color: _dark-mode(text);
  }

  .toggle-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    padding-top: 3px;
    cursor: pointer;

    &.selected {
      color: _palette(white);
      background: _palette(purple);
      border: 1px solid _palette(purple);
      width: 100px;
      height: 36px;
      box-shadow: 0px 0px 16px rgba(36, 36, 36, 0.2);
      border-radius: 20px;
      font-size: 16px;
      line-height: 24px;
      padding-top: 2px;
      font-weight: _font(weight-medium);
      margin-top: -4px;
    }

    &.disabled {
      opacity: 0.35;
      cursor: wait;
    }
  }
}
