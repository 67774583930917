.DeviceSelector {
  &__more-options-icon {
    path {
      fill: #ffffff;
    }
  }

  &__dialog-content {
    .MuiInputBase-root,
    .MuiButton-root {
      color: _palette(white);
      font-family: inherit;
    }
  }
}
