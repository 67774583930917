.Room {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 24px 12px;

  @media screen and (max-width: 640px) {
    padding: 0;
  }

  &__header {
    margin: 0 auto 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media screen and (max-width: 640px) {
      margin: 0;
    }
  }

  &__announcement {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  &__participants {
    display: flex;

    @media screen and (max-width: 640px) {
      flex-direction: column-reverse;
    }
  }

  &__navigation {
    &-label {
      position: absolute;
      font-size: 40px;
      line-height: 56px;
      opacity: 0.3;

      &--upper {
        &-left {
          top: 12%;
          left: 14%;
          // transform: rotate(-37.5deg);
        }

        &-right {
          top: 12%;
          right: 14%;
          // transform: rotate(37.5deg);
        }
      }

      &--lower {
        &-left {
          bottom: 12%;
          left: 14%;
          // transform: rotate(37.5deg);
        }

        &-right {
          bottom: 12%;
          right: 14%;
          // transform: rotate(-37.5deg);
        }
      }
    }

    &-arrow {
      position: absolute;
      cursor: pointer;
      transition: opacity 0.15s ease-out;
      z-index: 2;

      path {
        fill: #46464a;
      }

      &:hover {
        opacity: 0.7;
      }

      &--upper {
        &-left {
          top: 18%;
          left: 18%;
        }

        &-right {
          top: 18%;
          right: 18%;
        }
      }

      &--lower {
        &-left {
          bottom: 18%;
          left: 18%;
        }

        &-right {
          bottom: 18%;
          right: 18%;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.35;
      }
    }
  }
}

.waiting {
  &__ctn {
    // width: calc(100% - 80px);
    width: 200px;
    margin: 32px 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23E0E1E2FF' stroke-width='12' stroke-dasharray='16%2c12' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 24px;
    padding: 48px 0 40px;
    height: calc(100% - 48px);

    @media (max-width: 640px) {
      background-image: none;
    }

    [data-theme='dark'] & {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23555656FF' stroke-width='12' stroke-dasharray='16%2c12' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

      @media (max-width: 640px) {
        background-image: none;
      }
    }

    .GroupRoom & {
      svg path {
        fill: _palette(white);
      }
    }
  }
}

.Room--mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: #000000;

  .logo {
    position: absolute;
    top: 21px;
    left: 20px;

    svg {
      width: 130px;
      height: auto;

      path {
        fill: #ffffff;
      }
    }
  }

  &__footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 20px 20px;

    .Room__controls {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      &__left {
        display: flex;
      }
    }
  }
}

.GroupRoom {
  position: relative;
  padding: 0;

  .Participant__group {
    width: 100%;
    max-width: 840px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 840px;
    box-sizing: border-box;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(90deg, #1d3072 0%, #223883 100%);
    padding: 10px 16px;
    margin: 0 auto;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

    [data-theme='dark'] & {
      box-shadow: none;
      background: _dark-mode(dark-2);
    }

    .teleport-button {
      margin: auto;
    }

    &--left {
      flex: 1;
    }
    &--right {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }
}
