.modal {
  min-width: 440px;
  padding: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    button.modal__x {
      path {
        fill: #c6c7cc;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
