$events-content-max-width: 880px;

.Events {
  padding: 24px 24px 5%;
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $events-content-max-width;
    margin: 16px auto 32px;
  }

  &__list {
    max-width: $events-content-max-width;
    margin: 16px auto;

    &__section-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: _font(weight-bold);
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    &__item {
      margin: 0 -1px 20px;
      cursor: pointer;

      &__body {
        padding: 12px 20px;
      }

      &__title {
        &__row {
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            cursor: pointer;

            path {
              stroke: #ffffff;
            }

            &:hover,
            &.favorited {
              path {
                fill: #ffffff;
              }
            }
          }
        }
      }

      &__subtitle {
        display: flex;
        margin-top: -2px;

        > * {
          margin-right: 8px;
        }
      }

      &__description {
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__side-channel {
    width: 260px;
    margin: 0 24px 0 0;
  }

  &__content {
    flex: 1;
  }
}
