.top-nav {
  height: 100px;
  position: relative;
  // position: fixed;
  // top: 0;
  // right: max(calc(calc(100% - 1600px) / 2), 0);
  // left: max(calc(calc(100% - 1600px) / 2), 0); // issue
  padding: 0 100px;
  width: 100%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;

  @media screen and (max-width: 1400px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 640px) {
    --height: 64px;
    height: var(--height);
    min-height: var(--height);
    padding: 0 20px;
  }

  .logo {
    width: 160px;
    height: auto;
    animation-duration: 1s;
    animation-delay: 0;

    path {
      // fill: _palette(black);

      [data-theme='dark'] & {
        // fill: _dark-mode(white);
      }
    }

    @media screen and (max-width: 640px) {
      width: 160px;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    > span {
      height: 24px;
    }
  }

  .divider {
    height: 1px;
    width: 16px;
    background: #e5e7e9;
    margin-left: 40px;
    margin-right: 40px;

    [data-theme='dark'] & {
      background: _dark-mode(border-color);
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .nav-link {
    color: #202020;
    padding: 0;
    margin: 0;
    text-decoration: none;
    margin-left: 24px;

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }

  &--thin {
    height: $app-top-nav-thin-height;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
    padding: 0 80px;
    max-width: none;
    z-index: 2;

    .logo {
      width: 135px;

      path {
        // fill: _palette(black);

        [data-theme='dark'] & {
          // fill: _dark-mode(white);
        }
      }
    }

    a,
    .link {
      white-space: nowrap;
      font-weight: _font(weight-semibold);
      color: _palette(black) !important;
      font-family: $font-family-termina;
      text-transform: uppercase;
      font-size: 10.5px;
      line-height: 1.4;

      &:not(:first-child) {
        margin-left: 40px;
      }

      [data-theme='dark'] & {
        color: _dark-mode(text) !important;
      }

      &:hover {
        color: _palette(black) !important;

        [data-theme='dark'] & {
          color: _dark-mode(text) !important;
        }
      }
    }

    .divider {
      background: #2e53d0;
    }
  }
}

.nav {
  &__avatar {
    &__ctn {
      cursor: pointer;
    }
  }

  &__arrow {
    margin-left: 4px;
  }
}
