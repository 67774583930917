/*
 *
 * This file contains confidential information.
 * Unauthorized reproduction or distribution of this file is subject
 * to civil and criminal penalties.
 *
 * Copyright (C) 2020 Simeon Lee
 * All rights reserved.
 *
 */

html {
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

body,
input,
select,
textarea {
  color: _palette(fg);
  font-family: _font(family);
  font-weight: _font(weight-medium);
  letter-spacing: _font(letter-spacing);
  line-height: _font(line-height);
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  // @include breakpoint(xlarge) {
  //   font-size: 14pt;
  // }

  // @include breakpoint(large) {
  //   font-size: 12pt;
  // }

  // @include breakpoint(xxsmall) {
  //   font-size: 11pt;
  // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: _palette(black);
  font-weight: _font(weight-semibold);
  margin: 0;
  line-height: 1.2;
  font-family: _font(family);

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }
}

h1,
h2,
h3 {
  // letter-spacing: -0.02em;
}

h4,
h5,
h6 {
  letter-spacing: 0;
}

h1,
h2 {
  &.termina.uppercase {
    letter-spacing: -0.01em;
  }
}

h3,
h4,
h5,
h6 {
  &.termina.uppercase {
    letter-spacing: 0.01em;
  }
}

.termina {
  font-family: $font-family-termina;
}

.avenir {
  font-family: $font-family-avenir;
}

.uppercase {
  text-transform: uppercase;
}

h1 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: _font(weight-extrabold);

  &.termina {
    font-weight: _font(weight-bold);
  }

  &.mktg {
    margin-bottom: 16px;

    @media screen and (min-width: 640px) {
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 16px;
    }

    @media screen and (min-width: 768px) {
      font-size: 3rem;
      line-height: 3.6rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 4rem;
      line-height: 4.4rem;
      margin-bottom: 24px;
    }

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }
}

h2 {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: _font(weight-bold);
}

h3 {
  font-size: 1.17rem;
  line-height: 1.4;
  font-weight: _font(weight-bold);

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }

  &.mktg {
    [data-theme='dark'] & {
      color: _dark-mode(gray);
    }
  }
}

h4 {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: _font(weight-semibold);
  color: _palette(black);

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }

  &.mktg {
    color: #444547;
    font-weight: _font(weight);

    [data-theme='dark'] & {
      color: _dark-mode(gray);
    }
  }
}

h5 {
  font-size: 0.83rem;
  line-height: 1.5;
  color: _palette(black);
  font-weight: _font(weight-medium);

  &.mktg {
    color: _palette(black);
    font-weight: _font(weight);
  }
}

h6 {
  font-size: 0.75rem;
  line-height: 1.45;
  color: _palette(gray);
  font-weight: _font(weight);

  [data-theme='dark'] & {
    color: _dark-mode(gray);
  }
}

@include breakpoint(small) {
  h1 {
    // font-size: 2em;
  }

  h2 {
    // font-size: 1.5em;
  }

  h3 {
    // font-size: 1.25em;
  }
}

p {
  // margin: 0 0 _size(element-margin) 0;
}

strong,
b {
  color: _palette(fg-bold);
  font-weight: _font(weight-bold);
}

em,
i {
  font-style: italic;
}

a,
.link {
  @include vendor(
    'transition',
    (
      'color #{_duration(transition)} ease-in-out',
      'border-bottom-color #{_duration(transition)} ease-in-out'
    )
  );
  text-decoration: none;
  cursor: pointer;
  color: _palette(black);
  font-weight: _font(weight-semibold);
  display: inline-flex;
  align-items: center;

  &:hover {
    border-bottom-color: transparent;
    color: _palette(gray) !important;

    [data-theme='dark'] & {
      color: _dark-mode(text) !important;
    }
  }

  &:active {
    color: desaturate(darken(_palette(gray), 15), 5) !important;

    [data-theme='dark'] & {
      color: _dark-mode(text) !important;
    }
  }

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }

  &.black {
    color: _palette(black);

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }

  svg.link-icon {
    width: 20px;
    height: 20px;

    path {
      fill: _palette(gray);

      [data-theme='dark'] & {
        fill: _dark-mode(text);
      }
    }
  }

  &.back {
    font-size: 18px;
    line-height: 26px;
    color: _palette(black);

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }

    svg.link-icon {
      margin-bottom: 2px;
      margin-right: 1px;
      width: 16px;
      height: 16px;

      path {
        fill: _palette(black);

        [data-theme='dark'] & {
          fill: _dark-mode(white);
        }
      }
    }

    &:hover {
      color: _palette(black-light) !important;

      [data-theme='dark'] & {
        color: _dark-mode(text) !important;
      }

      svg.link-icon {
        path {
          fill: _palette(black-light);

          [data-theme='dark'] & {
            fill: _dark-mode(white);
          }
        }
      }
    }

    &.absolute-desktop {
      position: absolute;
      width: 100%;
      max-width: calc(1500px + 4px); // icon white-space compensation
      top: 40px;
      margin: 0 auto;
      padding: 0 100px;
    }
  }
}

.platinum {
  &--light {
    width: fit-content;
    background: -webkit-linear-gradient(
      -195deg,
      #ff3db3 16.41%,
      rgba(246, 244, 251, 0.75) 55.88%,
      #2edaf2 99.69%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--dark {
    width: fit-content;
    background: -webkit-linear-gradient(
      -195deg,
      #ff3db3 16.41%,
      #2edaf2 99.69%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: solid 4px _palette(border);
  font-style: italic;
  margin: 0 0 _size(element-margin) 0;
  padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4)
    _size(element-margin);
}

code {
  background: _palette(border-bg);
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-size: 0.9em;
  margin: 0 0 _size(element-margin) 0;

  code {
    display: block;
    line-height: 1.75;
    padding: 1em 1.5em;
    overflow-x: auto;
  }
}

hr {
  border: 0;
  border-bottom: solid 1px _palette(border);
  margin: _size(element-margin) 0;

  &.major {
    margin: (_size(element-margin) * 1.5) 0;
  }

  &.divider {
    border: 1px;
    overflow: visible;
    margin: 24px 0;
    border-top: 1px solid #e9e9e9;
    width: 100%;
    color: #757575;
    text-align: center;
  }

  &.form {
    border-color: #e5e7e9;
    width: 100%;
    margin: 0;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.section-title {
  height: 48px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8b9196;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.7px;
  user-select: none;

  &--mktg {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: _palette(black);
    margin-bottom: 16px;
    text-align: center;
    padding: 0 20px;

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }
}

.instruction {
  margin-top: 48px;
  background: #f4f5f6;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subtitle {
  font-weight: _font(weight);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: _palette(gray);

  [data-theme='dark'] & {
    color: _dark-mode(gray);
  }
}

.mini-label {
  font-weight: _font(weight);
  font-size: 12px;
  line-height: 16px;
  color: _palette(gray);

  [data-theme='dark'] & {
    color: _dark-mode(gray);
  }
}

.tagline {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.placeholder-color {
  color: #caccce;

  [data-theme='dark'] & {
    color: _dark-mode(border-color);
  }
}

.white {
  color: #ffffff;

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }
}

.light-purple {
  color: #6d73fa;

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }
}

.light-blue {
  color: #849fff;

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }
}

.black {
  color: _palette(black);

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }
}

.gray {
  color: _palette(gray);

  [data-theme='dark'] & {
    color: _palette(gray);
  }
}

.lavender-gray {
  color: #c7c7d0;

  [data-theme='dark'] & {
    color: _dark-mode(text);
  }
}

.yellow {
  color: yellow;

  [data-theme='dark'] & {
    color: yellow;
  }
}

.red {
  color: #ff2a48;

  [data-theme='dark'] & {
    color: #ff2a48;
  }
}

.gradient-purple-pink {
  width: fit-content;
  background: -webkit-linear-gradient(165deg, #ff3db3 25%, #2e36f2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  [data-theme='dark'] & {
    background: _dark-mode(text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.ellipsis {
  position: relative;

  &:after {
    overflow: hidden;
    position: absolute;
    left: 100%;
    animation: ellipsis steps(4, end) 1500ms infinite;
    -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}
