.avatar {
  border-radius: 50%;
  background: #f5f6f7;
  overflow: hidden;

  [data-theme='dark'] & {
    background: #444649;
  }

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: _palette(black);

      [data-theme='dark'] & {
        // fill: _palette(white);
      }
    }
  }

  &.light svg path {
    // width: fit-content;
    // fill: #e5e7e9;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    [data-theme='dark'] & {
      // fill: _dark-mode(text);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  &.no-border {
    border: 0;

    [data-theme='dark'] & {
      border: 0;
    }
  }
}

.avatar-24 {
  width: 24px;
  height: 24px;
}

.avatar-40 {
  width: 40px;
  height: 40px;
}

.avatar-80 {
  width: 80px;
  height: 80px;
}
