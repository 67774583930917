.CreateAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: calc(100vh - 100px);
  padding-bottom: 15%;
  flex: 1;
  box-sizing: border-box;

  &__form {
    width: 320px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    position: relative;
  }
}
