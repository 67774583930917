header {
  &.page-head {
    padding: 20px 0;
    background-position: 50%;
    background-size: cover;
    margin: -24px -24px 0;
    position: relative;
    height: 420px;

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .site-banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
