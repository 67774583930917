.settings {
  margin: 0 auto;
  padding: 40px 24px 24px;
  position: relative;
  min-height: calc(100vh - 100px);
  box-sizing: border-box;

  &__main {
    display: flex;
    align-items: flex-start;
  }

  &__left-nav {
    width: 260px;
    height: fit-content;
    margin-right: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0;

    &__header {
      padding: 16px 24px;
      box-sizing: border-box;
      width: 100%;
    }

    a {
      width: 100%;
      box-sizing: border-box;
      padding: 16px 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 440px;

    > div {
      padding: 24px;
      height: fit-content;
    }
  }
}

.avatar-upload {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}
