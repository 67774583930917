.Chat {
  width: 300px;
  background: _dark-mode(dark-1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  &__header {
    color: rgba(255, 255, 255, 0.35);
    height: 48px;
    font-weight: _font(weight-medium);
    text-transform: uppercase;
    letter-spacing: 0.16em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
  }

  &__body {
    height: calc(100% - 48px - 56px);
    width: 100%;
    padding: 16px 16px 8px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
  }

  &__message {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    padding: 5px 0;

    &__sender {
      color: #849fff;
      padding-right: 4px;
      font-weight: _font(weight-medium);
    }

    &__text {
      color: #ffffff;
      word-break: break-word;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    box-sizing: border-box;
  }

  &__input {
    color: _dark-mode(text);
    background: _dark-mode(dark-3);
    border-radius: 8px;
    height: 40px;
    padding: 0 40px 0 12px;
    width: 100%;
    line-height: 20px;
    font-size: 14px;

    &::placeholder {
      color: _dark-mode(border-color);
    }

    &__ctn {
      position: relative;
    }

    &__send-button {
      position: absolute;
      right: 4px;
      top: 4px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      transition: background 0.1s ease-out;

      &:hover,
      &:focus {
        background: rgba(164, 167, 255, 0.35);
        cursor: pointer;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
