.spiral {
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: rotate 1.5s linear infinite;
  width: 24px;
  height: 24px;

  path {
    fill: _palette(black);

    @media screen and (max-width: 640px) {
      fill: _palette(white);
    }

    [data-theme='dark'] & {
      fill: _palette(white);
    }
  }
}
