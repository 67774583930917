.Card {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    box-sizing: border-box;
    // border-right: 1px solid #e5e7e9;
    // border-bottom: 1px solid #e5e7e9;
    // border-left: 1px solid #e5e7e9;
    border-radius: 0 0 12px 12px;

    @media screen and (max-width: 640px) {
      border: 0;
      border-radius: 0;
    }

    [data-theme='dark'] & {
      // border: 0 solid _dark-mode(border-color);
      background: _dark-mode(dark-2);
      border-radius: 0;

      @media screen and (max-width: 640px) {
        background: transparent;
      }
    }
  }
}

hr {
  border-color: #e5e7e9;

  [data-theme='dark'] & {
    border-color: #555656;
  }
}
