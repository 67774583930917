html {
  overflow-x: hidden;
}

body {
  color: $black;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  /* prevent abrupt change in smoothing when animations complete (only in Safari) */
  -webkit-backface-visibility: hidden;
  cursor: default;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 100vw;
  margin: auto;
}

#__next {
  display: block;
  position: relative;
  min-height: 100vh;
  margin: 0 auto;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
}

// =====================================================
//    MEDIA QUERIES
// =====================================================

// Medium
@media (min-width: 768px) {
}
// Large
@media (max-width: 992px) {
}
// Extra large
@media (max-width: 1225px) {
}

@media (max-width: 544px) {
}
