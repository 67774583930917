$font-family-termina: termina, sans-serif;
$font-family-avenir: 'Avenir', sans-serif;

// Misc.
$misc: (
  z-index-base: 10000,
);

// Duration.
$duration: (
  menu: 0.35s,
  transition: 0.2s,
  banner: 2.5s,
  fast: 0.15s,
);

// Size.
$size: (
  border-radius: 10px,
  element-height: 2.75em,
  element-margin: 2em,
  inner: 65em,
);

$double-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

// =====================================================
//    Fonts
// =====================================================
$font: (
  family-title: (
    'Avenir',
    'Metropolis',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif,
  ),
  family-display: (
    'Avenir',
    'Metropolis',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif,
  ),
  family-text: (
    'Avenir',
    'Metropolis',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif,
  ),
  family: (
    'Avenir',
    'Metropolis',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif,
  ),
  system: (
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ),
  weight-thin: 100,
  weight-extralight: 200,
  weight-light: 300,
  weight: 400,
  weight-medium: 500,
  weight-semibold: 600,
  weight-bold: 700,
  weight-extrabold: 800,
  weight-black: 900,
  letter-spacing: 0,
  line-height: 1.5,
);

// Palette.
$palette: (
  white: #ffffff,
  black: #242427,
  black-light: #535357,
  pink: #ff3db2,
  purple-vibrant: #2e36f2,
  purple-dark: #32369c,
  bg: #242943,
  bg-alt: #2a2f4a,
  wehrlite: #a7a188,
  wehrlite-alt: #97927c,
  spotlight-1: #0c0c0c,
  fg: #434343,
  fg-bold: #434343,
  fg-light: rgba(244, 244, 255, 0.2),
  // border: rgba(212, 212, 255, 0.1),
  border: #e5e7e9,
  border-bg: rgba(212, 212, 255, 0.035),
  highlight: #9bf1ff,
  gray: #9597a1,
  red: #dd3131,
);

// Palette.
$dark-mode: (
  white: #ffffff,
  text: #ffffff,
  dark-1: #202020,
  dark-2: #242525,
  dark-3: #303030,
  border: 1px solid #555656,
  border-color: #555656,
  gray: #a0a4b5,
);

$border: 1px solid _palette(border);

// =====================================================
//    COLORS
// =====================================================

$black: _palette(black);
$white: #ffffff;

// =====================================================
//    Fonts Weight
// =====================================================
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

/* letter spacing
10 = 0.01em
20 = 0.02em
*/

$app-top-nav-thin-height: 60px;
$app-footer-height: 120px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
