.EventSettings {
  position: relative;

  &__left-nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 260px;
    margin-right: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: $border;

    [data-theme='dark'] & {
      border-right: _dark-mode(border);
    }

    &__header {
      padding: 32px 24px 16px;
      box-sizing: border-box;
      width: 100%;
    }

    a {
      width: 100%;
      box-sizing: border-box;
      padding: 16px 24px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    // flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 260px;

    > div {
      margin: 40px auto 24px;
      min-width: 400px;
    }
  }
}
