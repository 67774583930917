.Footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  animation-duration: 1s;
  animation-delay: 2s;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 640px) {
    display: none;
  }

  &--mktg {
    height: $app-footer-height;
    position: relative;
    padding: 0 100px;

    @media screen and (max-width: 1400px) {
      padding: 0 80px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    color: #cccccc;
    margin-right: 24px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    [data-theme='dark'] & {
      color: _dark-mode(gray);
    }

    .Footer--mktg & {
      color: #202020;

      [data-theme='dark'] & {
        color: _dark-mode(text);
      }
    }
  }

  .heart {
    margin-left: 4px;
    opacity: 0.35;
    font-size: 9px;
  }

  .mark-icon {
    path {
      fill: #202020;

      [data-theme='dark'] & {
        fill: _dark-mode(text);
      }
    }
  }
}
