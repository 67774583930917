.Participant {
  position: relative;
  width: 100%;

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-left: 12px;

      @media screen and (max-width: 640px) {
        margin-left: 0;
        margin-bottom: 10px;
      }
    }
  }

  &__group {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-flow: row wrap;
    max-width: 840px;
    margin: 0 auto;

    .GroupParticipant {
      margin: 6px;
      z-index: 1;

      .Participant__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h5 {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        max-width: 300px;
      }

      .muted-icon path {
        fill: #6d73fa;
      }

      button {
        width: 24px;
        height: 24px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &--1,
    &--2,
    &--3,
    &--4 {
      .GroupParticipant {
        // width: calc(calc(100% - 28px) / 2);
        width: 240px;
      }
    }

    &--5,
    &--6 {
      .GroupParticipant {
        width: calc(calc(100% - 42px) / 3);
      }
    }

    &--7,
    &--8 {
      .GroupParticipant {
        width: calc(calc(100% - 56px) / 4);
      }
    }
  }

  h3 {
    font-size: 20px;
    line-height: 32px;
    color: _palette(black);
    font-weight: _font(weight-medium);
    margin: 0;
    display: flex;
    align-items: center;
    // overflow-x: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
    // word-break: break-all;
    // max-width: 240px;

    > svg {
      margin: 0 6px 2px;
      width: 20px;
      height: 20px;
      color: #abafb4;
    }
  }

  .subtext {
    font-size: 16px;
    line-height: 24px;
    color: #b0b0b0;
  }

  .MuiIconButton-label svg path {
    fill: _palette(black);

    [data-theme='dark'] & {
      fill: _dark-mode(white);
    }
  }

  &Info {
    width: 100%;
    display: flex;
    justify-content: center;

    & video {
      filter: none;
    }

    & svg {
      // stroke: black;
      // stroke-width: 0.8px;
    }

    &.video-off {
      & video {
        filter: blur(4px) grayscale(1) brightness(0.5);
      }
    }

    &Container {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      width: 0;
    }
  }
}

.LocalParticipant--mobile {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20%;

  video {
    border-radius: 10px;
    width: 100%;
  }
}

.RemoteParticipant--mobile {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  video {
    width: 100%;
  }
}
