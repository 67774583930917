@mixin col-position {
  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      position: absolute;
      top: #{$i * 10}px;
      left: #{$i * 16}px;
    }
  }
}

@mixin room-position {
  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      position: absolute;
      top: #{$i * 10}px;
      left: calc(96px - #{$i * 16}px);
    }
  }
}

.Minimap {
  position: relative;
  width: 163.77px;
  height: 102.36px;
  margin-bottom: 16px;
  margin: -24px 16px 32px -48px;

  &__col {
    @include col-position;
    width: 99.77px;
    height: 62.36px;
  }

  &__room {
    @include room-position;
    // cursor: pointer;
    z-index: 999;

    &:hover {
      path {
        // fill: rgba(34,71,196,0.5);
      }
    }

    [data-theme='dark'] & {
      path {
        stroke: _dark-mode(border-color);
      }
    }
  }
}
