/**
 * Adobe Fonts
 * Termina 700
 */
@import url('https://use.typekit.net/bnv6xvu.css');

@font-face {
  font-family: 'Metropolis';
  font-weight: 100;
  src: url('../../assets/fonts/Metropolis/Metropolis-Thin.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 200;
  src: url('../../assets/fonts/Metropolis/Metropolis-ExtraLight.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 300;
  src: url('../../assets/fonts/Metropolis/Metropolis-Light.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  src: url('../../assets/fonts/Metropolis/Metropolis-Regular.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  src: url('../../assets/fonts/Metropolis/Metropolis-Medium.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  src: url('../../assets/fonts/Metropolis/Metropolis-SemiBold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 700;
  src: url('../../assets/fonts/Metropolis/Metropolis-Bold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 800;
  src: url('../../assets/fonts/Metropolis/Metropolis-ExtraBold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 900;
  src: url('../../assets/fonts/Metropolis/Metropolis-Black.otf')
    format('opentype');
}
