.Tile {
}

.LargeTile {
  animation-duration: 500ms;
  animation-delay: 650ms;
}

.MediumTile {
  animation-duration: 500ms;
  animation-delay: 550ms;
}

.SmallTile {
  animation-duration: 500ms;
  animation-delay: 450ms;
}
