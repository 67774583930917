.Home {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    margin: 16px auto 24px;
  }

  &__main {
    display: flex;
    margin: 16px auto;
    max-width: 1400px;
    min-height: calc(
      100vh - #{$app-top-nav-thin-height} - 24px - 24px - 16px - 16px - #{$app-footer-height}
    );
  }

  &__side-channel {
    width: 260px;
    margin: 0 24px 0 0;
  }

  &__profile {
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
    padding: 40px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__user-content {
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
    margin-top: 24px;
    padding: 16px;
  }

  &__content {
    flex: 1;
  }

  &__my-spaces {
    &--empty {
      padding: 16px 24px;
      margin: 120px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: fit-content;
      box-shadow: none !important;
      background: #f7f8f9 !important;

      [data-theme='dark'] & {
        background: _dark-mode(dark-2) !important;
      }
    }
  }
}
