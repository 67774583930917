.Landing {
  .dsktp {
    @media screen and (max-width: 640px) {
      display: none !important;
    }
  }

  .mbl {
    @media screen and (min-width: 641px) {
      display: none;
    }

    & h1.mktg,
    & h2.mktg,
    & h3.mktg,
    & h4.mktg,
    & h5.mktg,
    & h6.mktg,
    .subtitle {
      margin: 0 20px;
    }
  }

  .jumbotron {
    height: calc(100vh - 100px);
    width: 100%;
    max-width: 1500px;
    box-sizing: border-box;
    padding: 0 100px 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 1400px) {
      padding: 0 80px 6%;
    }

    @media screen and (max-width: 640px) {
      height: calc(100vh - 64px);
      padding: 0 0 10%;
      flex-direction: column;
      justify-content: flex-start;
      justify-content: center;
    }

    &__text {
      width: 50%;
      animation-duration: 1s;
      animation-delay: 1s;
      margin-bottom: 200px;

      @media screen and (max-width: 640px) {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .dsktp.video-twist {
      position: absolute;
      width: 60%;
      left: -10%;

      @media screen and (max-width: 640px) {
        display: none;
      }
    }
  }

  .dsktp.blob-hero {
    position: absolute;
    z-index: -99;
    animation-duration: 1s;
    animation-delay: 0.5s;
    transition: right 0.2s linear, top 0.2s linear, width 0.2s linear;
    display: none;

    path {
      fill: #fbfbff;
    }

    @media screen and (min-width: 320px) {
      // mobile
    }

    @media screen and (min-width: 640px) {
      // sm
      display: block;
      right: -60%;
      top: -60%;
      width: 120%;
    }

    @media screen and (min-width: 768px) {
      // md
    }

    @media screen and (min-width: 1024px) {
      // lg
    }

    @media screen and (min-width: 1280px) {
      // xl
      right: 2%;
      top: -14%;
      bottom: auto;
      top: auto;
      width: 42.5%;
      padding-bottom: 96px;
    }

    [data-theme='dark'] & {
      path {
        fill: #101010;
      }
    }
  }

  .mbl.disclaimer {
    padding: 0 20px;
    position: absolute;
    bottom: 20px;
  }

  .challenge {
    padding: 200px 0 400px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      margin-bottom: 64px;
    }
  }
}
