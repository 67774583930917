.Metaspace {
  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__upper-left {
    position: absolute;
    top: 16px;
    left: 24px;
    z-index: 2;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      svg {
        margin-right: 16px;

        path {
          fill: _palette(white);
        }
      }

      h6 {
        font-weight: 600;
      }
    }
  }

  &__upper-right {
    position: absolute;
    top: 16px;
    right: 24px;
  }

  @keyframes movedUpperLeft {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(78.5714286%, 78.5714286%); // 44% / 56%
    }
  }

  @keyframes movedUpperRight {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(-78.5714286%, 78.5714286%);
    }
  }

  @keyframes movedLowerLeft {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(78.5714286%, -78.5714286%);
    }
  }

  @keyframes movedLowerRight {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(-78.5714286%, -78.5714286%);
    }
  }

  &__border {
    $border-size: 56%;
    $movement-speed: 350ms;
    $animation-timing-function: linear;
    position: absolute;
    pointer-events: none;

    &--upper-left {
      top: 0;
      left: 0;
      width: $border-size;
      height: $border-size;

      &.movedUpperLeft {
        animation-name: movedUpperLeft;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &.movedLowerRight {
        animation-name: movedLowerRight;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &--outer {
        top: -44%;
        left: -44%;
        width: $border-size;
        height: $border-size;

        &.movedUpperLeft {
          animation-name: movedUpperLeft;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }

        &.movedLowerRight {
          animation-name: movedLowerRight;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }
      }
    }

    &--upper-right {
      top: 0;
      right: 0;
      width: $border-size;
      height: $border-size;

      &.movedUpperRight {
        animation-name: movedUpperRight;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &.movedLowerLeft {
        animation-name: movedLowerLeft;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &--outer {
        top: -44%;
        right: -44%;
        width: $border-size;
        height: $border-size;

        &.movedUpperRight {
          animation-name: movedUpperRight;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }

        &.movedLowerLeft {
          animation-name: movedLowerLeft;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }
      }
    }

    &--lower-right {
      bottom: 0;
      right: 0;
      width: $border-size;
      height: $border-size;

      &.movedUpperLeft {
        animation-name: movedUpperLeft;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &.movedLowerRight {
        animation-name: movedLowerRight;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &--outer {
        bottom: -44%;
        right: -44%;
        width: $border-size;
        height: $border-size;

        &.movedUpperLeft {
          animation-name: movedUpperLeft;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }

        &.movedLowerRight {
          animation-name: movedLowerRight;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }
      }
    }

    &--lower-left {
      bottom: 0;
      left: 0;
      width: $border-size;
      height: $border-size;

      &.movedUpperRight {
        animation-name: movedUpperRight;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &.movedLowerLeft {
        animation-name: movedLowerLeft;
        animation-duration: $movement-speed;
        animation-timing-function: $animation-timing-function;
      }

      &--outer {
        bottom: -44%;
        left: -44%;
        width: $border-size;
        height: $border-size;

        &.movedUpperRight {
          animation-name: movedUpperRight;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }

        &.movedLowerLeft {
          animation-name: movedLowerLeft;
          animation-duration: $movement-speed;
          animation-timing-function: $animation-timing-function;
        }
      }
    }
  }
}
