.form {
  &__row {
    display: flex;
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    font-weight: _font(weight-semibold);
    color: _palette(black);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;

    [data-theme='dark'] & {
      color: _dark-mode(text);
    }
  }

  &__input {
    &.mktg {
      border: 1px solid #e5e7e9;
      // height: 56px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      background: #ffffff;
      padding: 12px;
      font-size: 18px;
      line-height: 20px;
      width: 100%;
      margin: 0 0 20px 0;
      box-sizing: border-box;
      outline: none;
      position: relative;
      transition: box-shadow 100ms linear, border-color 100ms ease-out;
      box-shadow: none;
      -webkit-appearance: none;
      caret-color: _palette(purple-vibrant);

      [data-theme='dark'] & {
        border: _dark-mode(border);
        background: _dark-mode(dark-3);
        color: _dark-mode(text);
        caret-color: #ffffff;
      }

      &::placeholder {
        color: #caccce;

        [data-theme='dark'] & {
          color: _dark-mode(border-color);
        }
      }

      &:focus {
        border: 1px solid _palette(pink);
        box-shadow: 0 0 0 8px #ffecf7;

        [data-theme='dark'] & {
          border: _dark-mode(border);
          box-shadow: 0 0 0 8px _dark-mode(dark-3);
        }
      }
    }
  }
}

.validation-error {
  font-size: 14px;
  line-height: 20px;
  color: _palette(red);
  margin: -12px 0 20px;
}

.range-divider:after {
  content: '—';
  color: #e5e7e9;
  height: 100%;
  margin: 0 8px;
  display: flex;
  align-items: center;

  [data-theme='dark'] & {
    color: _dark-mode(border-color);
  }
}

.get-started__form {
  margin: 0 0 32px;
  animation-duration: 1s;
  animation-delay: 1.5s;
  border-radius: 10px;
  background: #ffffff;
  position: relative;
  z-index: 99;

  @media screen and (min-width: 641px) {
    box-shadow: 0px 0px 12px rgba(32, 48, 64, 0.12);
    width: 360px;
  }

  @media screen and (max-width: 640px) {
    border-radius: 0;
    background: transparent;
    margin: 0;
    border: none;
    padding: 20px;
    width: 360px;
  }

  [data-theme='dark'] & {
    background: _dark-mode(dark-2);

    @media screen and (max-width: 640px) {
      background: transparent;
      border: none;
    }
  }

  h3 {
    @media screen and (max-width: 640px) {
      text-align: center;
      padding: 0 10%;
    }
  }

  .subtitle {
    animation-duration: 1s;
    animation-delay: 2s;
  }
}

hr.form {
  margin: 0 -24px 20px;
  width: calc(100% + 48px);
}

.menu {
  display: flex;
  margin-bottom: 16px;

  input[type='radio'] {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  label.menu-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    background: #f6f3ff;
    border-radius: 8px;
    transition: all 0.3s ease-out;
    padding: 16px 12px 14px;

    [data-theme='dark'] & {
      background: _dark-mode(dark-2);
    }

    .subtitle {
      color: #727480;
    }
  }

  input[type='radio']:checked + label {
    background-color: _palette(purple-vibrant);
    h5,
    .subtitle {
      color: #ffffff;
    }
  }
}
