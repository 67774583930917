.Controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  // visibility: 'hidden',
  // '&.showControls, &:hover': {
  //   transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
  //   opacity: 1,
  //   visibility: 'visible',
  //   // transform: 'translate(50%, 0px)',
  // },
  // opacity: 0,
  // transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',

  // button + button {
  //   margin-left: 16px;
  // }
}
